import React, { useContext, useState, useEffect } from "react";
import * as XLSX from "xlsx";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaEye, FaRegEdit } from "react-icons/fa";

import {
  IconButton,
  Button,
  CircularProgress,
  Typography,
  Alert,
  Box,
  Pagination,
} from "@mui/material";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import PlayerProfileModal from "./PlayerProfileModal";
import EditUserModal from "./EditUserModal";
import { AuthContext } from "../auth/ContextAuthentication";
// import TextField from '@mui/material/TextField';
import { MenuItem, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CircleLoader from "./CircleLoader";
import Snackbar from "@mui/material/Snackbar";
import useAxiosConfig from "../config/AxiosConfig";
import { ErrorLoadingContext } from "../auth/ErrorLoadingContext";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const TableData = (props) => {
  const axiosInstance = useAxiosConfig();
  const headerData = props.headerData;
  const { playerData, setPlayerData } = useContext(AuthContext);
  // const {  } = useContext(AuthContext);
  const { adminRole } = useContext(AuthContext);
  const { loading, setIsLoading } = useContext(ErrorLoadingContext);
  const [page, setPage] = useState(1);
  // const [rowsPerPage] = useState(10);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [suspend, setSuspend] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [city, setCity] = useState("All");
  const [positionFilter, setPositionFilter] = useState("All");
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(null);
  const { allPlayerData, setAllPlayerData } = useContext(AuthContext);
  const [excelData, setExcelData] = useState([]);
  const { setLoading, setError } = useContext(ErrorLoadingContext);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  useEffect(() => {
    const filtered = allPlayerData.filter((data) => {
      const attributes = data.attributes || {};

      const nameMatch = searchQuery
        ? data.fullName?.toLowerCase().includes(searchQuery.toLowerCase())
        : true;

      const cityMatch =
        city === "All" ? true : data.city?.toLowerCase() === city.toLowerCase();

      const positionMatch =
        positionFilter === "All"
          ? true
          : attributes.position?.toLowerCase() === positionFilter.toLowerCase();

      return nameMatch && cityMatch && positionMatch;
    });

    setFilteredData(filtered);
    // setPage(1);
  }, [searchQuery, city, positionFilter]);

  useEffect(() => {
    fetchAllPlayers();
  }, [page, searchQuery, city, startDate, endDate]);

  useEffect(() => {
    fetchPlayersForExcel();
  }, []);

  const fetchPlayersForExcel = async () => {
    let response = await axiosInstance.get("api/admin/get-all-users");
    // console.log('responseeeeeeee', response.data.users)
    setExcelData(response.data.users);
  };

    console.count("testttttttt");

  const fetchAllPlayers = async () => {
    setLoading(true);
    try {
      let url = `api/admin/get-all-users?page=${page}`;

      // Add search params if they exist
      if (searchQuery) {
        url += `&search=${searchQuery}`;
      }

      if (city !== "All") {
        url += `&city=${city}`;
      }

      // Add date parameters if both dates are present
      if (startDate && endDate) {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }

      const response = await axiosInstance.get(url);
      setAllPlayerData(response.data.users);
      setTotalPages(response.data.totalPages);
      setTotalUsers(response.data.totalUsers);
      setLoading(false);
    } catch (error) {
      setError("Error fetching data");
      setLoading(false);
    }
  };
  // const getCurrentPageData = () => {
  //   const startIndex = (page - 1) * rowsPerPage;
  //   const endIndex = startIndex + rowsPerPage;
  //   return filteredData.slice(startIndex, endIndex);
  // };

  // Calculate total pages
  // const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handleSearchInputChange = (event) => {
    const value = event.target.value || "";
    setSearchQuery(value);
  };

  // const handleCityChange = (event) => {
  //   const value = event.target.value || "All";
  //   setCity(value);
  // };

  // const handlePositionFilter = (event) => {
  //   const value = event.target.value || "All";
  //   setPositionFilter(value);
  // };
  const handleChange = (event, newPage) => {
    setPage(newPage);
  };
  // Function to open the modal with player details
  const handleViewClick = (player) => {
    setSelectedPlayer(player);
  };
  // Function to close the modal
  const handleCloseModal = () => {
    setSelectedPlayer(null);
  };
  // Function to open the edit modal
  const handleEditClick = (user) => {
    setEditUser(user);
  };
  // Function to close the edit modal
  const handleCloseEditModal = () => {
    setEditUser(null);
  };
  // Function to open the delete confirmation modal
  const handleDeleteClick = (itemId, suspendValue) => {
    setDeleteItemId(itemId);
    setIsDeleteModalOpen(true);
    setSuspend(!suspendValue);
  };
  // Function to close the delete confirmation modal
  const handleCloseDeleteModal = () => {
    setDeleteItemId(null);
    setIsDeleteModalOpen(false);
  };
  const handleSendNotificationClick = () => {
    // Remove duplicate rows based on the id field
    setOpen(true);
  };
  const handleDialogSubmit = async () => {
    const uniqueRowsMap = selectedRowsData.reduce((map, row) => {
      map[row._id] = row;
      return map;
    }, {});

    // Convert the object back to an array of unique rows
    const uniqueRows = Object.values(uniqueRowsMap);

    const notificationMessage = document.getElementById("w3review").value;

    const notificationData = {
      userIds: selectedRowsData,
      message: notificationMessage,
    };

    setOpen(false);
    try {
      const response = await axiosInstance.post(
        "api/admin/send-notification",
        notificationData
      );
      if (response.data.status == true) {
        setOpenSnackbar(true);
        setUpdateStatus({
          message: "Notification sent successfully",
          success: true,
        });
      }
    } catch (error) {
      console.log(error);
      setUpdateStatus({
        message: "Failed to sent Notification",
        success: false,
      });
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // Replace your handleRowSelect function with this updated version:
  const handleRowSelect = (id) => {
    // Update the selection state in allPlayerData since that's what you're rendering
    const updatedAllPlayerData = allPlayerData.map((row) => {
      if (row._id === id) {
        const updatedRow = { ...row, selected: !row.selected };

        // Update selectedRowsData based on the new selection state
        if (updatedRow.selected) {
          setSelectedRowsData((prevData) => [...prevData, updatedRow._id]);
        } else {
          setSelectedRowsData((prevData) =>
            prevData.filter((item) => item !== id)
          );
        }

        return updatedRow;
      }
      return row;
    });

    // Update the state with the modified data
    setAllPlayerData(updatedAllPlayerData);

    // Also update filteredData to keep it in sync
    const updatedFilteredData = filteredData.map((row) => {
      if (row._id === id) {
        return { ...row, selected: !row.selected };
      }
      return row;
    });

    setFilteredData(updatedFilteredData);
  };

  // Similarly, update the handleSelectAll function:
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    // Update all rows in allPlayerData
    const updatedAllPlayerData = allPlayerData.map((row) => ({
      ...row,
      selected: newSelectAll,
    }));

    // Update the selectedRowsData based on the new selection state
    if (newSelectAll) {
      // If selecting all, add all IDs to selectedRowsData
      setSelectedRowsData(updatedAllPlayerData.map((row) => row._id));
    } else {
      // If deselecting all, clear selectedRowsData
      setSelectedRowsData([]);
    }

    setAllPlayerData(updatedAllPlayerData);

    // Also update filteredData to keep it in sync
    const updatedFilteredData = filteredData.map((row) => ({
      ...row,
      selected: newSelectAll,
    }));

    setFilteredData(updatedFilteredData);
  };
  // const displaySelectedRowsData = () => {
  //   // console.log(selectedRowsData);
  //   // You can render or use this data as needed
  // };
  // const handleSelectAll = () => {
  //   setSelectAll(!selectAll);
  //   // Update the selection state of all rows in filteredData based on selectAll
  //   const updatedData = filteredData.map((row) => {
  //     const updatedRow = { ...row, selected: !selectAll };
  //     if (!selectAll && updatedRow.selected) {
  //       // Add to selectedRowsData when selecting all
  //       // setSelectedRowsData((prevData) => [...prevData, updatedRow]);
  //       setSelectedRowsData((prevData) => {
  //         return [...prevData, updatedRow._id];
  //       });
  //     } else if (selectAll) {
  //       // Remove from selectedRowsData when deselecting all
  //       setSelectedRowsData([]);
  //     }
  //     return updatedRow;
  //   });
  //   setFilteredData(updatedData);
  //   displaySelectedRowsData(); // Update the displayed selected rows
  // };

  // Function to handle export to Excel
  const handleExportToExcel = () => {
    const formattedData = excelData.map((row) => ({
      FullName: row.fullName || "N/A",
      Email: row.email || "N/A",
      PhoneNO: row.phone_no || "N/A",
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    worksheet["!cols"] = [{ wch: 30 }, { wch: 40 }, { wch: 30 }];

    XLSX.utils.sheet_add_aoa(worksheet, [["FullName", "Email", "PhoneNO"]], {
      origin: "A1",
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Players");

    XLSX.writeFile(workbook, "PlayersData.xlsx");
  };

  const tags = [
    { id: 1, name: "Box to Box" },
    { id: 2, name: "Dead ball specialist" },
    { id: 3, name: "Flair" },
    { id: 4, name: "Engine" },
    { id: 5, name: "Goal machine" },
    { id: 6, name: "Technician" },
    { id: 7, name: "Assist king" },
    { id: 8, name: "Flash" },
    { id: 9, name: "Lethal finishing" },
    { id: 10, name: "Long range" },
    { id: 11, name: "Powerhouse" },
    { id: 12, name: "Tiki-taka" },
    { id: 13, name: "Brick wall" },
    { id: 14, name: "Agile" },
    { id: 15, name: "Maestro" },
    { id: 16, name: "Leader" },
    { id: 17, name: "Playmaker" },
    { id: 18, name: "Aerial play" },
    { id: 19, name: "Reliable" },
    { id: 20, name: "Marksman" },
  ];
  // Function to handle the actual deletion
  const handleDelete = async () => {
    if (deleteItemId) {
      try {
        await axiosInstance.put(`api/admin/suspend/${deleteItemId}`, {
          suspend,
        });
        // Once the deletion is successful, update the playerData state accordingly
        const updatedPlayerData = await axiosInstance.get("api/admin/getusers");
        setPlayerData(updatedPlayerData.data.users);
        // Close the delete confirmation modal
        handleCloseDeleteModal();
      } catch (error) {
        console.error(error);
      }
    }
  };
  const updateUserData = async (updatedUser) => {
    const updatedPlayerData = await axiosInstance.get("api/admin/getusers");
    setPlayerData(updatedPlayerData.data.users);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <h2 className="text-xl font-bold mb-4">Users</h2>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          severity={updateStatus?.success ? "success" : "error"}
          onClose={handleSnackbarClose}
        >
          {updateStatus?.message}
        </Alert>
      </Snackbar>
      {/* Filters  */}

      <div className="flex flex-wrap justify-center gap-4 mb-3">
        <div>
          <Button
            variant="contained"
            onClick={handleSendNotificationClick}
            disabled={adminRole !== "Super Admin"}
            sx={{
              backgroundColor:
                adminRole === "Super Admin" ? "#0C7240" : "#B0B0B0",
              boxShadow: "none",
              "&:hover": {
                backgroundColor:
                  adminRole === "Super Admin" ? "#0C7240" : "#B0B0B0",
                boxShadow: "none",
              },
              cursor: adminRole === "Super Admin" ? "pointer" : "not-allowed",
            }}
          >
            Send Notifications
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            onClick={handleSelectAll}
            sx={{
              backgroundColor: "#0C7240",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#0C7240",
                boxShadow: "none",
              },
            }}
          >
            {selectAll ? "Deselect All" : "Select All"}
          </Button>
        </div>
        <div>
          <TextField
            type="text"
            label="Search by name"
            value={searchQuery}
            onChange={handleSearchInputChange}
            size="small"
          />
        </div>
        {/* <div>
          <TextField
            type="text"
            value={city}
            label="City"
            size="small"
            sx={{ maxWidth: "180px" }}
            select
            onChange={handleCityChange}
          >
            <MenuItem value="Islamabad">Islamabad</MenuItem>
            <MenuItem value="Rawalpindi">Rawalpindi</MenuItem>
            <MenuItem value="All">All</MenuItem>
          </TextField>
        </div> */}
        {/* <div>
          <TextField
            type="text"
            value={positionFilter}
            size="small"
            sx={{ maxWidth: "180px", width: "120px" }}
            label="Position"
            select
            onChange={handlePositionFilter}
          >
            <MenuItem value="Forward">Forward</MenuItem>
            <MenuItem value="Defender">Defender</MenuItem>
            <MenuItem value="Midfielder">Midfielder</MenuItem>
            <MenuItem value="GoalKeeper">Goalkeeper</MenuItem>
            <MenuItem value="All">All</MenuItem>
          </TextField>
        </div> */}

        <div>
          <TextField
            label="Start Date"
            type="date"
            size="small"
            sx={{ maxWidth: "180px" }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            style={{ width: 300 }}
          />
        </div>
        <div>
          <TextField
            label="End Date"
            type="date"
            size="small"
            sx={{ maxWidth: "180px" }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            style={{ width: 300 }}
          />
        </div>

        <div>
          <Button
            variant="contained"
            onClick={handleExportToExcel}
            disabled={adminRole !== "Super Admin"}
            sx={{
              backgroundColor:
                adminRole === "Super Admin" ? "#0C7240" : "#B0B0B0",
              boxShadow: "none",
              "&:hover": {
                backgroundColor:
                  adminRole === "Super Admin" ? "#0C7240" : "#B0B0B0",
                boxShadow: "none",
              },
              cursor: adminRole === "Super Admin" ? "pointer" : "not-allowed",
            }}
          >
            Export
          </Button>
        </div>
      </div>

      <TableContainer
        component={Paper}
        sx={{
          marginX: "auto",
          maxWidth: {
            sm: "98%",
          },
          minWidth: {
            sm: "500px",
          },
          maxHeight: {
            md: "71vh",
            sm: "90vh",
          },
          overflowY: "auto",
          // overflowY: "auto",
          // position: "relative",
          top: "4rem",
        }}
      >
        <Table aria-label="simple table">
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#ffff",
              zIndex: "1",
            }}
          >
            <TableRow>
              {headerData.map((header, index) => (
                <TableCell key={index} sx={{ fontWeight: "bold" }}>
                  {header}
                </TableCell>
              ))}
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <CircleLoader />
            ) : allPlayerData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No data/row found
                </TableCell>
              </TableRow>
            ) : (
              allPlayerData.map((data) => (
                <TableRow
                  key={data._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <input
                      type="checkbox"
                      checked={data.selected || false}
                      onChange={() => handleRowSelect(data._id)}
                      style={{ cursor: "pointer" }}
                    />
                  </TableCell>
                  <TableCell align="left">{data.fullName || "N/A"}</TableCell>
                  <TableCell align="left">{data.email || "N/A"}</TableCell>
                  <TableCell align="left">{data.phone_no}</TableCell>
                  <TableCell align="left">{data.role || "N/A"}</TableCell>
                  {/* <TableCell align="left">{data.city}</TableCell> */}
                  <TableCell align="left">
                    {data.isSuspended ? (
                      <Button
                        size="small"
                        variant="text"
                        color="error"
                        onClick={() =>
                          handleDeleteClick(data._id, data.isSuspended)
                        }
                        disabled={adminRole !== "Super Admin"}
                      >
                        Unsuspend
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        variant="text"
                        color="secondary"
                        onClick={() =>
                          handleDeleteClick(data._id, data.isSuspended)
                        }
                        disabled={adminRole !== "Super Admin"}
                      >
                        Suspend
                      </Button>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {data.isVerified ? (
                      <IconButton sx={{ color: "green" }}>
                        <DoneOutlineIcon />
                      </IconButton>
                    ) : (
                      <IconButton sx={{ color: "red" }}>
                        <ClearOutlinedIcon fontSize="medium" />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <IconButton onClick={() => handleViewClick(data)}>
                        <FaEye
                          style={{
                            cursor: "pointer",
                            fontSize: 24,
                            color: "green",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => handleEditClick(data)}
                        disabled={adminRole !== "Super Admin"}
                        style={{
                          cursor:
                            adminRole === "Super Admin"
                              ? "pointer"
                              : "not-allowed",
                          opacity: adminRole === "Super Admin" ? 1 : 0.5,
                        }}
                      >
                        <FaRegEdit
                          style={{
                            fontSize: 24,
                            color:
                              adminRole === "Super Admin" ? "purple" : "grey",
                          }}
                        />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {selectedPlayer && (
          <PlayerProfileModal
            player={selectedPlayer}
            handleClose={handleCloseModal}
          />
        )}
        {editUser && (
          <EditUserModal
            user={editUser}
            handleClose={handleCloseEditModal}
            handleUpdate={updateUserData}
            tags={tags}
          />
        )}
        {/* <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            padding: "1rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            zIndex: 1000,
          }}
        ></Box> */}
      </TableContainer>
      <div className="flex justify-center mt-2 font-bold">
        <Pagination count={totalPages} page={page} onChange={handleChange} />
      </div>

      {/* Render the delete confirmation modal */}
      {isDeleteModalOpen && (
        <div
          className="max-[500px]:w-72 w-1/3"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "5px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            padding: "20px",
            backgroundColor: "#fff",
          }}
        >
          <div className="flex flex-col gap-3">
            <h2 className={suspend ? "text-red-700" : "text-green-700"}>
              {suspend ? "Confirm Suspension!" : "Revoke Suspension!"}
            </h2>
            <p className="sm:text-sm">
              {suspend
                ? "Are you sure you want to suspend this account?"
                : "Are you sure you want to revoke the suspension?"}
            </p>
            <div className="flex justify-center gap-3">
              <Button
                variant="contained"
                color={suspend ? "secondary" : "error"}
                size="small"
                onClick={handleDelete}
              >
                {loading ? <CircularProgress size={20} /> : "Confirm"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleCloseDeleteModal}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Send Notifications
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Typography gutterBottom>
              Please Enter the notification that you want to send to the{" "}
              <span
                style={{
                  color: "red",
                  fontStyle: "italic",
                  textDecoration: "underline",
                }}
              >
                Selected
              </span>{" "}
              users
            </Typography>
            <textarea
              id="w3review"
              name="w3review"
              rows="4"
              cols="50"
              style={{
                width: "100%",
                height: "150px",
                padding: "12px 20px",
                boxSizing: "border-box",
                border: "2px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#f8f8f8",
                fontSize: "16px",
                resize: "none",
              }}
            ></textarea>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleDialogSubmit} variant="contained">
              Send
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
};
export default TableData;
