import React, { useContext, useEffect, useState } from "react";
import MatchTable from "../component/MatchTable";
import { AuthContext } from "../auth/ContextAuthentication";
import { ErrorLoadingContext } from "../auth/ErrorLoadingContext";
import useAxiosConfig from "../config/AxiosConfig";
import { Pagination } from "@mui/material";

const MatchProfile = () => {
  const axiosInstance = useAxiosConfig();
  const { setMatchData } = useContext(AuthContext);
  const { setLoading, setError } = useContext(ErrorLoadingContext);
  const [totalPages, setTotalPages] = useState("");
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [groundName, setGroundName] = useState("");

  const headerData = [
    "MatchType",
    "Organizer",
    "Status",
    "GroundName",
    "Date",
    "StartTime",
    "EndTime",
  ];
  useEffect(() => {
    fetchApi();
  }, [setMatchData, page, startDate, endDate, groundName]);

  const handlePageChange = async (event, page) => {
    setPage(page);
  };

  useEffect(() => {
    console.log("page debugging", page);
  }, [page]);

  const handleDate = (value, type) => {
    if (type === "start") {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  const handleGroundName = (value) => {
    setGroundName(value);
  };

  const fetchApi = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `api/admin/allmatches?page=${
          page
        }&groundName=${groundName}&startDate=${startDate}&endDate=${endDate}`
      );
      setMatchData(response.data.matches);
      // setCurrentPage(response.data.matches);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (error) {
      setError("Error fetching data");
      setLoading(false);
    }
  };
  return (
    <>
      <h2 className="text-xl font-bold mb-4">Matches</h2>
      <div>
        <MatchTable
          headerData={headerData}
          totalPages={totalPages}
          handleDate={handleDate}
          handleGroundName={handleGroundName}
        />
      </div>
      <div className="flex justify-center mt-1">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default MatchProfile;
