import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Snackbar,
  Alert,
  Box,
} from "@mui/material";
import useAxiosConfig from "../config/AxiosConfig";
import WeekDayPicker from "./WeekDayPicker";
import { FiUpload } from "react-icons/fi";

const amenitiesList = [
  "Flood lights",
  "Seating area",
  "Cameras",
  "Sports Equipments",
  "Electronic score board",
  "Parking area",
  "Rest rooms",
];

const AddGroundModal = ({
  showModal,
  handleClose,
  handleSave,
  newGroundData,
  handleInputChange,
  setNewGroundData,
  managersName,
  setManagersName,
}) => {
  const axiosInstance = useAxiosConfig();
  const [addStatus, setAddStatus] = useState(null);
  const [updatedMembers, setUpdatedMembers] = useState([]);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [multipleTimes, setMultipleTimes] = useState(true);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [featureImage, setFeatureImage] = useState(null);
  const [otherImage, setOtherImage] = useState(null);
  const [data, setData] = useState({
    name: "",
    managerName: "",
  });

  useEffect(() => {
    if (managersName && managersName?.length > 0) {
      const updatedManagers = managersName.filter(
        (manager) => manager.fullName !== undefined
      );
      setUpdatedMembers(updatedManagers);
    }
  }, [managersName]);

  const handleImageUpload = async (e) => {
    const imageFile = e.target.files[0];

    if (!imageFile) return;

    try {
      const formData = new FormData();
      formData.append("img", imageFile);
      // console.log(formData);

      // Send a POST request to the image upload API
      const response = await axiosInstance.post("api/upload/image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Get the uploaded image URL from the response and update newGroundData
      const imageUrl = response.data.img_url;
      // console.log(imageUrl);

      // Use setNewGroundData from props to update the state in GroundProfile component
      setNewGroundData((prevData) => ({
        ...prevData,
        images: [...prevData.images, imageUrl], // Assuming images is an array
      }));

      // Optionally, display a success message
      setAddStatus({ message: "Image uploaded successfully", success: true });
    } catch (error) {
      console.error("Error uploading image:", error);
      setAddStatus({ message: "Failed to upload image", success: false });
    }
  };

  const handleAddClick = async () => {
    let coordinate = [];
    const parsedLongitude = parseFloat(longitude); // Use parseFloat for decimal values
    const parsedLatitude = parseFloat(latitude);
    coordinate.push(parsedLongitude);
    coordinate.push(parsedLatitude);
    let loc = {
      type: "Point",
      coordinates: coordinate,
    };

    let data = {};
    data = { ...newGroundData, loc };

    try {
      // Send a POST request to save the new ground data
      const response = await axiosInstance.post("api/admin/createGround", data);
      // Call the callback function to handle saving and updating data
      handleSave();
      // Set a success message
      setAddStatus({ message: "Ground added successfully", success: true });
    } catch (error) {
      console.error("Error adding ground:", error);
      setAddStatus({
        message: "Failed to add ground, some fields are empty",
        success: false,
      });
    }
  };

  useEffect(() => {
    const getManagers = async () => {
      try {
        // Send a POST request to save the new ground data
        const response = await axiosInstance.get("api/ground/managers");

        setManagersName(response?.data?.managers);
      } catch (error) {
        console.error("Error getting managers:", error);
      }
    };

    getManagers();
  }, [setManagersName]);

  const handleDayChange = (event, newDays) => {
    setSelectedDays(newDays);
  };

  const handleToggle = (amenity) => {
    setSelectedAmenities(
      (prev) =>
        prev.includes(amenity)
          ? prev.filter((item) => item !== amenity) // Remove if already selected
          : [...prev, amenity] // Add if not selected
    );
  };

  return (
    <>
      <Dialog
        open={showModal}
        onClose={handleClose}
        maxWidth="lg"
        //   fullWidth
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DialogTitle>Add New Ground</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              required
              id="groundName"
              name="groundName"
              label="Ground Name"
              variant="outlined"
              value={data.name}
              onChange={handleInputChange}
              sx={{
                "& label.Mui-focused": {
                  color: "#0C7240",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#0C7240",
                },
                margin: "10px ",
              }}
            />

            <TextField
              required
              id="managerName"
              name="managerName"
              label="Mananger Name"
              variant="outlined"
              value={data.managerName}
              onChange={handleInputChange}
              sx={{
                "& label.Mui-focused": {
                  color: "#0C7240",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#0C7240",
                },
                margin: "10px ",
              }}
            />

            <TextField
              required
              id="location"
              name="location"
              label="Location"
              variant="outlined"
              value={data.location}
              onChange={handleInputChange}
              sx={{
                "& label.Mui-focused": {
                  color: "#0C7240",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#0C7240",
                },
                margin: "10px ",
              }}
            />

            <h3 className="text-lg font-bold">Multiple Times</h3>
            <div className="my-4">
              <label className="mr-8 ml-2">
                <input type="radio" name="status" value="enable" /> Enable
              </label>
              <label>
                <input type="radio" name="status" value="disable" /> Disable
              </label>
            </div>

            <div className="mt-4">
              {multipleTimes ? (
                <>
                  <h3 className="text-md font-semibold">Time Slot</h3>
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Opening Time"
                    variant="outlined"
                    // value={newGroundData.name}
                    // onChange={handleInputChange}
                    sx={{
                      "& label.Mui-focused": {
                        color: "#0C7240",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#0C7240",
                      },
                      margin: "10px ",
                    }}
                  />

                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Closing Time"
                    variant="outlined"
                    // value={newGroundData.name}
                    // onChange={handleInputChange}
                    sx={{
                      "& label.Mui-focused": {
                        color: "#0C7240",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#0C7240",
                      },
                      margin: "10px ",
                    }}
                  />

                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Peak Time"
                    variant="outlined"
                    // value={newGroundData.name}
                    // onChange={handleInputChange}
                    sx={{
                      "& label.Mui-focused": {
                        color: "#0C7240",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#0C7240",
                      },
                      margin: "10px ",
                    }}
                  />
                </>
              ) : (
                <>
                  <h3 className="text-md font-semibold">Time Slot 1</h3>
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Opening Time 1"
                    variant="outlined"
                    // value={newGroundData.name}
                    // onChange={handleInputChange}
                    sx={{
                      "& label.Mui-focused": {
                        color: "#0C7240",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#0C7240",
                      },
                      margin: "10px ",
                    }}
                  />
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Closing Time 1"
                    variant="outlined"
                    // value={newGroundData.name}
                    // onChange={handleInputChange}
                    sx={{
                      "& label.Mui-focused": {
                        color: "#0C7240",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#0C7240",
                      },
                      margin: "10px ",
                    }}
                  />
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Peak Time 1"
                    variant="outlined"
                    // value={newGroundData.name}
                    // onChange={handleInputChange}
                    sx={{
                      "& label.Mui-focused": {
                        color: "#0C7240",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#0C7240",
                      },
                      margin: "10px ",
                    }}
                  />
                  <WeekDayPicker
                    title={"Weekdays 1"}
                    selectedDays={selectedDays}
                    setSelectedDays={setSelectedDays}
                  />

                  <br />
                  <h3 className="text-md font-semibold">Time Slot 2</h3>
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Opening Time 2"
                    variant="outlined"
                    // value={newGroundData.name}
                    // onChange={handleInputChange}
                    sx={{
                      "& label.Mui-focused": {
                        color: "#0C7240",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#0C7240",
                      },
                      margin: "10px ",
                    }}
                  />
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Closing Time 2"
                    variant="outlined"
                    // value={newGroundData.name}
                    // onChange={handleInputChange}
                    sx={{
                      "& label.Mui-focused": {
                        color: "#0C7240",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#0C7240",
                      },
                      margin: "10px ",
                    }}
                  />
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Peak Time 1"
                    variant="outlined"
                    // value={newGroundData.name}
                    // onChange={handleInputChange}
                    sx={{
                      "& label.Mui-focused": {
                        color: "#0C7240",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#0C7240",
                      },
                      margin: "10px ",
                    }}
                  />
                  <WeekDayPicker title={"Weekdays 2"} />
                </>
              )}
            </div>

            <div className="mt-8 mb-2">
              <TextField
                required
                id="name"
                name="name"
                label="Number of fields"
                variant="outlined"
                // value={newGroundData.name}
                // onChange={handleInputChange}
                sx={{
                  "& label.Mui-focused": {
                    color: "#0C7240",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#0C7240",
                  },
                  margin: "10px ",
                }}
              />

              <TextField
                id="perPlayer"
                name="perPlayer"
                label="Advance Payment %"
                variant="outlined"
                type="number"
                // value={newGroundData.perPlayer}
                // onChange={handleInputChange}
                sx={{
                  "& label.Mui-focused": {
                    color: "#0C7240",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#0C7240",
                  },
                  margin: "10px ",
                }}
              />
            </div>

            <h3 className="text-lg font-bold">Live Bookings</h3>
            <div className="my-2">
              <label className="mr-8 ml-2">
                <input type="radio" name="status" value="enable" /> Enable
              </label>
              <label>
                <input type="radio" name="status" value="disable" /> Disable
              </label>
            </div>

            <h3 className="text-md font-semibold mt-4">Coordinates</h3>

            <div className="flex mt-2">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TextField
                  required
                  id="longitude"
                  name="coordinates"
                  label="Longitude"
                  variant="outlined"
                  type="number"
                  value={longitude}
                  onChange={(e) => setLongitude(e.target.value)}
                  sx={{
                    "& label.Mui-focused": {
                      color: "#0C7240",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#0C7240",
                    },
                    margin: "10px ",
                  }}
                />
                {longitude > 99 ? (
                  <span
                    style={{
                      marginLeft: "10px",
                      fontSize: "10px",
                      color: "red",
                    }}
                  >
                    Please enter a valid longitude between -99 and 99
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TextField
                  required
                  id="latitude"
                  name="coordinates"
                  label="Latitude"
                  variant="outlined"
                  type="number"
                  value={latitude}
                  onChange={(e) => setLatitude(e.target.value)}
                  sx={{
                    "& label.Mui-focused": {
                      color: "#0C7240",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#0C7240",
                    },
                    margin: "10px ",
                  }}
                />
                {latitude > 99 ? (
                  <span
                    style={{
                      marginLeft: "10px",
                      fontSize: "10px",
                      color: "red",
                    }}
                  >
                    Please enter a valid latitude between -99 and 99
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>

            <br />
            {/* <h3 className="text-lg font-bold">Feature Image</h3>

            <input
              type="file"
              accept="image/*"
              className="ml-2"
              onChange={handleImageUpload}
            />
            <br />
            <h3 className="text-lg font-bold mt-8">Other Images (Optional)</h3>

            <input
              type="file"
              accept="image/*"
              className="ml-2"
              onChange={handleImageUpload}
            /> */}

            {/* Feature Image Upload */}
            <h3 className="text-lg font-bold mt-4">Feature Image</h3>
            <label className="flex flex-col items-center justify-center w-64 h-40 border-2 border-dashed border-gray-400 rounded-lg cursor-pointer hover:border-gray-600 transition">
              <input
                type="file"
                accept="image/*"
                className="hidden"
                // onChange={handleFeatureImageUpload}
              />
              {featureImage ? (
                <img
                  src={featureImage}
                  alt="Feature"
                  className="w-full h-full object-cover rounded-lg"
                />
              ) : (
                <div className="flex flex-col items-center text-gray-500">
                  <FiUpload size={24} />
                  <span className="mt-2 text-sm">Upload</span>
                </div>
              )}
            </label>

            {/* Other Images Upload */}
            <h3 className="text-lg font-bold mt-8">Other Images (Optional)</h3>
            <label className="flex flex-col items-center justify-center w-64 h-40 border-2 border-dashed border-gray-400 rounded-lg cursor-pointer hover:border-gray-600 transition">
              <input
                type="file"
                accept="image/*"
                className="hidden"
                // onChange={handleOtherImageUpload}
              />
              {otherImage ? (
                <img
                  src={otherImage}
                  alt="Other"
                  className="w-full h-full object-cover rounded-lg"
                />
              ) : (
                <div className="flex flex-col items-center text-gray-500">
                  <FiUpload size={24} />
                  <span className="mt-2 text-sm">Upload</span>
                </div>
              )}
            </label>

            <h2 className="text-lg font-semibold mb-4 mt-6">
              Amenities Available
            </h2>

            <div className="grid grid-cols-2 gap-3">
              {amenitiesList.map((amenity, index) => (
                <label key={index} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={selectedAmenities.includes(amenity)}
                    onChange={() => handleToggle(amenity)}
                    className="w-4 h-4 accent-green-600"
                  />
                  <span>{amenity}</span>
                </label>
              ))}
            </div>
            {/* <TextField
required
id="images"
name="images"
label="Images"
variant="outlined"
type="text"
value={newGroundData.images}
onChange={handleInputChange}
sx={{
    '& label.Mui-focused': {
        color: '#0C7240',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0C7240',
    },
    margin: '10px ',
}}
/> */}

            {/* Add more fields as needed */}
          </form>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "center", gap: "10px", padding: "10px" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddClick}
            disabled={addStatus !== null}
          >
            {addStatus !== null ? "Adding..." : "Add Ground"}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
            style={{ marginLeft: "8px" }}
          >
            Close
          </Button>
        </DialogActions>
        {/* Snackbar for displaying the add status message */}
        {addStatus !== null && (
          <Box>
            <Snackbar
              open={true}
              autoHideDuration={5000}
              onClose={() => setAddStatus(null)}
            >
              <Alert
                severity={addStatus.success ? "success" : "error"}
                onClose={() => setAddStatus(null)}
              >
                {addStatus.message}
              </Alert>
            </Snackbar>
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default AddGroundModal;
