import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const days = [
  { label: "S", value: "sun" },
  { label: "M", value: "mon" },
  { label: "T", value: "tue" },
  { label: "W", value: "wed" },
  { label: "T", value: "thu" },
  { label: "F", value: "fri" },
  { label: "S", value: "sat" },
];

const WeekDayPicker = ({ title, selectedDays, setSelectedDays }) => {
  const handleDayChange = (event, newDays) => {
    setSelectedDays(newDays); // Update state in parent
  };

  return (
    <div>
      <h3 className="text-md font-semibold">{title}</h3>

      <ToggleButtonGroup
        value={selectedDays}
        onChange={handleDayChange}
        aria-label={title}
        size="small"
        sx={{ display: "flex", gap: 1, mt: 1 }}
      >
        {days.map((day) => (
          <ToggleButton
            key={day.value}
            value={day.value}
            sx={{
              borderRadius: "50%",
              width: 40,
              height: 40,
              fontSize: "16px",
              fontWeight: "bold",
              backgroundColor: selectedDays?.includes(day.value)
                ? "#0C7240"
                : "#f5f5f5",
              color: selectedDays?.includes(day.value) ? "#fff" : "#000",
              "&.Mui-selected": { backgroundColor: "#0C7240", color: "#fff" },
            }}
          >
            {day.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

export default WeekDayPicker;
